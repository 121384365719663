import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { lightGray } from './colors';
import Markdown from './Markdown';

const Container = styled.footer`
    width: 100%;
    text-align: center;
    padding: 30px;
    font-size: 12px;
    margin-top: 50px;
    border-top: 1px solid ${lightGray};
    box-shadow: 0px 0px 10px 6px #eee;
`;

const Footer = ({ footer }) => (
    <Container>
        <Markdown source={footer} />
    </Container>
);

Footer.propTypes = {
    footer: PropTypes.string,
};

export default Footer;
