/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { Routes } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components/macro';
import content from '../content';
import ScrollToTop from './ScrollToTop';
import Navigation from './Navigation';
import Footer from './Footer';
import Page from './Page';
import StartPage from './StartPage';
import { mainColor } from './colors';
import { getPages, getStartPage } from '../services/fetchContent';

const GlobalStyle = createGlobalStyle`
html {
    height: 100%;
}
body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    height: 100%;
}
h1,
h2,
h3 {
    color: ${mainColor};
}

h2 {
    text-transform: uppercase;
    font-weight: normal;
    margin: 0 0 20px 0;
}
h1 {
    margin: 20px 0 30px 0;
}

img {
    max-width: 100%;
}

a, a:visited {
    color: ${mainColor};
}

* {
    box-sizing: border-box;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
`;
const App = () => {
    const [pages, setPages] = useState();
    useEffect(() => {
        getPages(window.location.search.includes('preview=true')).then(
            setPages,
        );
    }, []);

    const [startPage, setStartPage] = useState();
    useEffect(() => {
        if (!startPage) {
            getStartPage(window.location.search.includes('preview=true')).then(
                (page) => setStartPage(page),
            );
        }
    }, [startPage]);

    return (
        <>
            <GlobalStyle />
            <BrowserRouter>
                <ScrollToTop>
                    <Navigation navigation={content.navigation} />
                    <Routes>
                        <Route
                            path="/"
                            end
                            Component={() => <StartPage page={startPage} />}
                        />
                        {pages &&
                            Object.entries(pages).map(([path, page]) => (
                                <Route
                                    key={path}
                                    path={path}
                                    end
                                    Component={() => (
                                        <Page
                                            page={page}
                                            navigation={content.navigation}
                                        />
                                    )}
                                />
                            ))}
                    </Routes>
                    <Footer footer={startPage ? startPage.footer : ''} />
                </ScrollToTop>
            </BrowserRouter>
        </>
    );
};

export default App;
