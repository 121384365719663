import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import Helmet from 'react-helmet';
import styled from 'styled-components/macro';
import Section from './Section';
import Hero from './Hero';
import { mainColor } from './colors';

const Content = styled.div`
    flex-grow: 1;
    margin: 10px;
    min-height: calc(100% - 43px - 95px);

    @media only screen and (min-device-width: 640px) {
        padding: 0 20px;
    }

    @media only screen and (min-device-width: 800px) {
        padding: 0 20px;
        max-width: 1200px;
        margin: 20px auto;
    }
    > * {
        margin-bottom: 60px;
    }
    h1,
    h2,
    h3 {
        color: ${mainColor};
    }
    img {
        max-width: 100%;
    }
`;

const Page = ({ page }) => {
    const location = useLocation();
    const { title, description, keywords } = page || {};
    useEffect(() => {
        const element = document.getElementById(location.hash.replace('#', ''));

        setTimeout(() => {
            window.scrollTo({
                behavior: element ? 'smooth' : 'auto',
                top: element ? element.offsetTop : 0,
            });
        }, 100);
    });
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                {keywords && <meta name="keywords" content={keywords} />}
                <meta name="name" content={title} />
                <meta
                    name="canonicalUrl"
                    content={`https://fallan.se${location.pathname}`}
                />
            </Helmet>

            {page && page.hero && <Hero />}
            {page && (
                <Content>
                    {page.sections &&
                        page.sections.map((section, key) => (
                            <Section
                                key={key}
                                content={section}
                                variant={page.variant}
                            />
                        ))}
                </Content>
            )}
            {!page && <div>Sidan kan inte hittas</div>}
        </>
    );
};

Page.propTypes = {
    page: PropTypes.object,
};

export default Page;
