import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, [location]);

    return children;
};

ScrollToTop.propTypes = {
    location: PropTypes.object,
    children: PropTypes.any,
};

export default ScrollToTop;
