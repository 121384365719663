import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { mainColor, lightGray } from './colors';

const List = styled.div`
    display: grid;
    gap: 14px;

    @media only screen and (min-device-width: 640px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (min-device-width: 800px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

const Document = styled.a`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: black;
    background-color: ${lightGray};
    padding: 10px;
    text-align: left;
    text-decoration: none;
    box-shadow: 0 3px 7px #e0e0e0;

    &:hover {
        background-color: ${mainColor};
        color: #fff;
    }
`;

const DocumentTitle = styled.div`
    margin-right: 30px;
`;

const DocumentType = styled.div`
    font-size: 9px;
    text-transform: uppercase;
    color: #808080;
`;

const Documents = ({ className, title, documents }) => (
    <div className={className}>
        <h2>{title}</h2>
        <List>
            {documents &&
                documents.map(document => (
                    <Document
                        key={document.url}
                        href={document.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <DocumentTitle>{document.title}</DocumentTitle>
                        <DocumentType>{document.type}</DocumentType>
                    </Document>
                ))}
        </List>
    </div>
);

Documents.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    documents: PropTypes.arrayOf(PropTypes.shape()),
};

export default Documents;
