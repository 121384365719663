import { createClient } from 'contentful';

const client = (preview) =>
    createClient({
        space: '02zurwbax0rj',
        accessToken: preview
            ? '7bae38022425857958d4e48f5a80395aabd5013bc7e91dc12c4d9af0b4898f55'
            : '0ea30c416e38cc9b63c20497f0c2332e47885745ab7c9317392d9af42833eb12',
        host: preview ? 'preview.contentful.com' : undefined,
    });

const parseNewsItem = (newsItem) =>
    newsItem.fields && {
        type: 'newsItem',
        text: newsItem.fields.text,
        title: newsItem.fields.title,
        date: newsItem.fields.date,
    };

const parseDocument = (document) =>
    document.fields && {
        type: 'pdf',
        title: document.fields.title,
        url: document.fields.file.url,
    };

const parseSection = (section) => {
    switch (section.sys.contentType.sys.id) {
        case 'news':
            return {
                type: 'news',
                title: section.fields.title,
                items: section.fields.items.map(parseNewsItem).filter(i => i),
            };
        case 'sectionGroup':
            return {
                type: 'sectionGroup',
                items: section.fields.items.map(parseSection),
                maxColumns: section.fields.maxColumns,
            };
        case 'person':
            return {
                type: 'person',
                name: section.fields.name,
                title: section.fields.title,
                imageUrl:
                    section.fields.image &&
                    section.fields.image.fields.file.url,
            };
        case 'documents':
            return {
                type: 'documents',
                title: section.fields.title,
                documents: section.fields.documents.map(parseDocument).filter(doc => doc),
            };
        case 'text':
            return {
                type: 'markdown',
                text: section.fields.text,
            };
        default:
            return null;
    }
};
const parsePage = (page) => {
    const firstSection = {
        type: 'markdown',
        text: page.fields.text,
    };
    const sections = (page.fields.sections || []).map(parseSection);
    return {
        path: page.fields.path,
        title: page.fields.title,
        sections: [firstSection, ...sections],
        hero: page.fields.path === '/',
        variant: page.fields.variant,
        description: page.fields.description,
        keywords: page.fields.keywords,
    };
};

const parseStartPage = (page) => ({
    events: (page.fields.events || []).map(parseNewsItem).filter(i => i),
    news: (page.fields.news || []).map(parseNewsItem).filter(i => i),
    text: page.fields.text,
    footer: page.fields.footer,
});

export const getStartPage = (preview) =>
    client(preview)
        .getEntries({
            content_type: 'startPage',
            include: 5,
        })
        .then((entries) => entries.items.map(parseStartPage)[0]);

export const getPages = (preview) =>
    client(preview)
        .getEntries({
            content_type: 'page',
            include: 5,
        })
        .then((entries) =>
            entries.items
                .map(parsePage)
                .reduce((pages, page) => ({ ...pages, [page.path]: page }), {}),
        );
