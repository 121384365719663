import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import hero640 from '../../static/hero-640.jpg';
import hero800 from '../../static/hero-800.jpg';
import hero1024 from '../../static/hero-1024.jpg';
import hero from '../../static/hero.jpg';

const Image = styled.div`
    background-image: url(${hero640});
    background-position: center;
    height: 155px;
    display: flex;
    align-items: center;

    @media only screen and (min-device-width: 641px) {
        background-image: url(${hero800});
        height: 194px;
    }

    @media only screen and (min-device-width: 801px) {
        background-image: url(${hero1024});
        height: 194px;
    }

    @media only screen and (min-device-width: 1025px) {
        background-image: url(${hero});
        height: 349px;
    }
`;

const Overlay = styled.div`
background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
`;

const Hero = ({ children }) => (
    <Overlay>
        <Image>{children}</Image>
    </Overlay>
);

Hero.propTypes = {
    children: PropTypes.any,
};

export default Hero;
