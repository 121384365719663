import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { mainColor, lightGray } from './colors';

const Image = styled.div`
    width: 75px;
    height: 95px;
    border-radius: 10px;
    background: no-repeat center;
    margin-right: 20px;
    background-color: ${lightGray};
    background-image: url('${props => props.imageUrl}');
    background-size: cover;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    color: ${mainColor};
`;

const Person = ({ className, title, name, imageUrl }) => (
    <div className={className}>
        <Image imageUrl={imageUrl} />
        <div>
            <Title>{title}</Title>
            <div>{name}</div>
        </div>
    </div>
);

Person.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string,
    imageUrl: PropTypes.string,
};

export default styled(Person)`
    height: 95px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
`;
