export const markdown = text => ({ type: "markdown", text });

export const person = (name, title, imageUrl) => ({
  type: "person",
  title,
  name,
  imageUrl
});

export const navigationItem = (name, location) => ({ name, location });

export const newsItem = (date, title, text) => ({ date, title, text });

export const page = (title, sections, hero = false) => ({
  title,
  sections,
  hero
});
