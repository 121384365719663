/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

const protocol = /^(\/\/|https?:\/\/|mailto:)/;

const MarkdownLink = ({ href, children, ...rest }) =>
    protocol.test(href) ? (
        <a href={href} {...rest}>
            {children}
        </a>
    ) : (
        <Link to={href} {...rest}>
            {children}
        </Link>
    );

MarkdownLink.propTypes = {
    href: PropTypes.string,
    children: PropTypes.oneOf([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
};

const Markdown = ({ source }) => (
    <ReactMarkdown components={{ link: MarkdownLink }}>{source}</ReactMarkdown>
);

Markdown.propTypes = {
    source: PropTypes.string,
};

export default Markdown;
