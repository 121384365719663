import React from 'react';
import PropTypes from 'prop-types';
import styled from  'styled-components/macro';

const repeat = (max) => ({single, children}) => single ? 1 : Math.min(React.Children.count(children), max);
const repeat2 = repeat(2);
const repeat3 = repeat(3);

const List = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 40px;
    row-gap: 50px;
    @media only screen and (min-device-width: 640px) {
        grid-template-columns: repeat(${repeat2}, 1fr);
    }
    @media only screen and (min-device-width: 1200px) {
        grid-template-columns: repeat(${repeat3}, 1fr);
    }
`;

const News = ({ header, children, className, single = false }) => (
    <div className={className}>
        <h2>{header}</h2>
        <List single={single}>{children}</List>
    </div>
);

News.propTypes = {
    className: PropTypes.string,
    header: PropTypes.string,
    single: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.object),
}

export default News;
