import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components/macro';
import Hero from './Hero';
import NewsItem from './NewsItem';
import News from './News';
import Markdown from './Markdown';
import { white } from './colors';

const FullWidth = styled.div`
    margin: 0;
    max-width: 1200px;
    margin: 0px auto;
`;

const StartPageNews = styled(News)`
    padding: 10px;
    @media only screen and (min-device-width: 640px) {
        padding: 20px;
    }
`;

const Events = styled(News)`
    padding: 20px 10px;
    background-color: #eaf3f5;
    margin-bottom: 40px;
    @media only screen and (min-device-width: 640px) {
        padding: 20px;
    }
`;

const Text = styled.div`
    padding: 20px 10px;
    background-color: #f7eded;
    margin-bottom: 40px;
    @media only screen and (min-device-width: 640px) {
        padding: 20px;
    }
`;

const meta = {
    title: 'Fållans fastighetsägareförening',
    description:
        'Fållans fastighetsägareförening består av fastighetsägare inom området Fållan, som hör till Trångsund i Huddinge kommun.',
    keywords: '',
    canonicalUrl: 'https://fallan.se/',
};

const Title = styled.h1`
    margin: 0 10px;
    color: ${white};
    text-shadow: 0px 0px 7px black;
    @media only screen and (min-device-width: 640px) {
        margin: 0 20px;
    }
`;

const StartPage = ({ page = {} }) => {
    const { events, news, text } = page || {};
    return (
        <>
            <Helmet>
                <title>Fållans fastighetsägarförening</title>
                {Object.entries(meta).map(([name, content]) => (
                    <meta key={name} name={name} content={content} />
                ))}
            </Helmet>

            <FullWidth>
                <Hero>
                    <Title>Fållans fastighetsägar&shy;förening</Title>
                </Hero>
                {events && events.length > 0 && (
                    <Events
                        header="Kommande händelser"
                        single={events.length === 1}
                    >
                        {events.map((event, index) => (
                            <NewsItem
                                key={index}
                                date={event.date}
                                title={event.title}
                            >
                                <Markdown source={event.text} />
                            </NewsItem>
                        ))}
                    </Events>
                )}

                {news && (
                    <StartPageNews header="Nyheter" single={news.length === 1}>
                        {news.map((newsItem, index) => (
                            <NewsItem
                                key={index}
                                date={newsItem.date}
                                title={newsItem.title}
                            >
                                <Markdown source={newsItem.text} />
                            </NewsItem>
                        ))}
                    </StartPageNews>
                )}
                <Text>
                    <Markdown source={text} />
                </Text>
            </FullWidth>
        </>
    );
};

StartPage.propTypes = {
    page: PropTypes.object,
};

export default StartPage;
