import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { mainColor, white } from './colors';

const Nav = styled.nav`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    z-index: 2;
    a {
        color: ${mainColor};
        text-decoration: none;
    }
`;

const Burger = styled.button`
    display: flex;
    flex-wrap: wrap;
    width: 30px;
    justify-content: center;
    align-items: center;
    margin: 10px;
    padding: 0;
    border: none;
    background-color: transparent;
    @media only screen and (min-device-width: 640px) {
        display: none;
    }
`;

const BurgerLine = styled.div`
    display: block;
    width: 80%;
    background-color: ${mainColor};
    height: 2px;
    margin: 3px;
`;

const ItemList = styled.ul`
    display: block;
    position: absolute;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 3 0 auto;
    top: ${(props) => (props.active ? '45px' : '-100%')};
    transition: top 0.3s;
    left: 0;
    padding: 0;
    margin: 0;
    box-shadow: 0 0 5px #aaa;
    background-color: ${white};

    @media only screen and (min-device-width: 640px) {
        display: flex;
        justify-content: space-around;
        position: inherit;
        top: auto;
        left: auto;
        box-shadow: none;
    }
`;

const Item = styled(NavLink)`
    display: block;
    margin: 20px 30px;
    @media only screen and (min-device-width: 640px) {
        margin: 0;
        padding: 8px 4px;
        &.active {
            border-bottom: 2px solid ${mainColor};
        }
    }

    @media only screen and (min-device-width: 1024px) {
        font-size: 20px;
        padding: 16px 8px;
    }
    &.active {
        font-weight: bold;
    }
`;

class Navigation extends React.Component {
    constructor() {
        super();
        this.state = {
            expanded: false,
        };
    }

    toggleExpand() {
        this.setState((prevState) => ({
            expanded: !prevState.expanded,
        }));
    }

    render() {
        const { className, navigation } = this.props;
        const toggleExpand = () => this.toggleExpand();
        return (
            <div className={className}>
                <Nav>
                    <Burger
                        type="button"
                        aria-label="Expandera menyn"
                        onClick={toggleExpand}
                    >
                        <BurgerLine />
                        <BurgerLine />
                        <BurgerLine />
                    </Burger>

                    <ItemList active={this.state.expanded}>
                        {navigation.map((item, index) => (
                            <Item
                                key={index}
                                end
                                to={item.location}
                                onClick={toggleExpand}
                            >
                                {item.name}
                            </Item>
                        ))}
                    </ItemList>
                </Nav>
            </div>
        );
    }
}

Navigation.propTypes = {
    className: PropTypes.string,
    navigation: PropTypes.array,
};

export default styled(Navigation)`
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 10px #aaa;
    border-bottom: 1px solid #aaa;
    background-color: ${white};
`;
