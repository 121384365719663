import { navigationItem } from './util';

const content = {
    navigation: [
        navigationItem('FFF', '/'),
        navigationItem('Styrelsen', '/styrelsen/'),
        navigationItem('Stadgar', '/stadgar/'),
        navigationItem('Dokument', '/dokument/'),
        navigationItem('Grannsamverkan', '/grannsamverkan/'),
    ],
    current: '/',
};

export default content;
