import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Date = styled.div`
    font-style: italic;
    white-space: nowrap;
    margin-bottom: 10px;
`;

const Title = styled.h3`
    margin: 0;
`;

const Container = styled.div`
    margin: -8px;
    padding: 8px;
    position: relative;
    :hover {
        ${(props) =>
            props.expandable
                ? 'box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);'
                : ''}
    }
`;

const Content = styled.div`
    @media only screen and (min-device-width: 640px) {
        max-height: ${(props) => (props.expanded ? '2000px' : '585px')};
        overflow-y: auto;
        transition: max-height 0.25s;
    }
`;

const Fade = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    height: 100px;
    width: calc(100% - 16px);
    position: absolute;
    bottom: 8px;
`;
const NewsItem = ({ date, title, children }) => {
    const [expanded, setExpanded] = useState(false);
    const content = useRef();
    const [expandable, setExpandable] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (content.current) {
                setExpandable(
                    content.current.clientHeight > 585 &&
                        document.body.clientWidth >= 640,
                );
            }
        }, 100);
    }, [content]);

    return (
        <Container
            expandable={expandable}
            onClick={() => setExpanded((ex) => !ex)}
        >
            <Content expanded={expanded}>
                <div ref={content}>
                    <Title>{title}</Title>
                    <Date>{date}</Date>
                    {children}
                </div>
            </Content>
            {expandable && !expanded && (
                <Fade>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 -256 1792 1792"
                        width="20px"
                        height="20px"
                    >
                        <g
                            transform="matrix(1,0,0,-1,68.338983,1133.5593)"
                            id="g3003"
                        >
                            <path
                                d="m 1611,832 q 0,-53 -37,-90 L 923,91 Q 885,53 832,53 778,53 742,91 L 91,742 q -38,36 -38,90 0,53 38,91 l 74,75 q 39,37 91,37 53,0 90,-37 l 486,-486 486,486 q 37,37 90,37 52,0 91,-37 l 75,-75 q 37,-39 37,-91 z"
                                id="path3005"
                                fill="rgba(0,0,0,0.50)"
                            />
                        </g>
                    </svg>
                </Fade>
            )}
        </Container>
    );
};

NewsItem.propTypes = {
    date: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node,
};
export default NewsItem;
