import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Markdown from './Markdown';
import News from './News';
import NewsItem from './NewsItem';
import Person from './Person';
import Documents from './Documents';

const Cols2 = styled.div`
    @media only screen and (min-device-width: 640px) {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr;
    }
`;

const Cols3 = styled.div`
    @media only screen and (min-device-width: 640px) {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (min-device-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

const Cols4 = styled.div`
    @media only screen and (min-device-width: 640px) {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (min-device-width: 900px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media only screen and (min-device-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
    }
`;

const DocumentsItem = styled(Documents)`
    margin-bottom: 30px;
`;

const selectGroup = maxColumns => {
    switch (maxColumns) {
        case 2:
            return Cols2;
        case 3:
            return Cols3;
        case 4:
            return Cols4;
        default:
            return null;
    }
};

const Item = ({ content }) => {
    switch (content.type) {
        case 'text':
            return <div>{content.text}</div>;
        case 'markdown':
            return (
                <div>
                    <Markdown source={content.text} />
                </div>
            );
        case 'news':
            return (
                <News header={content.title}>
                    {content.items &&
                        content.items.map((item, itemKey) => (
                            <NewsItem
                                key={itemKey}
                                date={item.date}
                                title={item.title}
                                single={content.items.length === 1}
                            >
                                <Markdown source={item.text} />
                            </NewsItem>
                        ))}
                </News>
            );
        case 'person':
            return <Person {...content} />;
        case 'documents':
            return <DocumentsItem {...content} />;
        case 'sectionGroup':
            const Group = selectGroup(content.maxColumns);
            const children = content.items.map((item, index) => (
                <Item key={index} content={item} />
            ));
            if (Group) {
                return <Group>{children}</Group>;
            }
            return <div>{children}</div>;

        default:
            return null;
    }
};

Item.propTypes = {
    content: PropTypes.any,
};

const Section = ({ content }) => {
    return <Item content={content} />;
};

Section.propTypes = {
    content: PropTypes.any,
};

export default Section;
